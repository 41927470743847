.contato {
    font-family: 'Roboto Slab', serif;
    font-size: 22px;
    color: var(--text-black);
    padding-bottom: 40px;
}

.contato a {
    text-decoration: none;
    color: var(--text-black);
}

.contato a:hover {
    color: var(--text-blue);
}

.contato .title {
    color: var(--text-blue);
    margin-top: 40px;
    margin-bottom: 10px;
}

.contato .contato-text {
    font-size: 28px;
}

.contato .contato-options  .whatsapp-logo {
    width: 30px;
    vertical-align: middle;
    padding-right: 10px;
}

.contato .contato-maps {
    margin-top: 20px;
}

.contato .contato-info {
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
    color: var(--text-black);
    display: grid;
    grid-template-areas: 'div div';
    grid-template-columns: 50% 50%;
    padding: 20px;
}

.contato .contato-info address {
    font-style: normal;
}

@media screen and (max-width: 870px) {
    .contato .contato-info {
        display: block;
    }
}