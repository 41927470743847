.products .products-menu {
  width: 300px;
  height: 100%;
  position: absolute;
  z-index: 10;
}

.products .products-menu .products-menu-header {
  font-family: 'Roboto Slab', serif;
  font-size: 24px;
  text-align: center;
  background-color: var(--blue);
  color: var(--white);
  padding: 20px 0;
}

.products .products-menu .products-menu-nav {
  background-color: var(--dark-blue);
  font-family: 'Bree Serif', serif;
  padding-top: 20px;
  padding-bottom: 40px;
}

.products .products-menu .products-menu-nav ul {
  margin: 0;
  padding: 0 30px;
  list-style: none;
}

.products .products-menu .products-menu-nav ul a {
  color: var(--white);
  text-decoration: none;
}

.products .products-menu .products-menu-nav .product-menu-item {
  font-weight: bold;
  font-size: 20px;
  padding: 10px 0;
}

.products .products-menu .products-menu-nav .product-submenu .product-menu-item {
  font-weight: normal;
  font-size: 18px;
  padding: 5px 0;
}

.product-menu-item.active a {
  font-weight: bold;
  text-decoration: underline !important;
}
