.quem-somos {
    padding-bottom: 40px;
}

.quem-somos .quem-somos-text {
    font-family: 'Roboto Slab', serif;
    font-size: 22px;
    color: var(--text-black);
}

.quem-somos .quem-somos-text .title,
.quem-somos .quem-somos-info .title {
    color: var(--text-blue);
}
.quem-somos .quem-somos-info {
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
    color: var(--text-black);
    display: grid;
    grid-template-areas: 'div div';
    grid-template-columns: 50% 50%;
    padding: 20px;
}

.quem-somos .quem-somos-info address {
    font-style: normal;
}

@media screen and (max-width: 920px) {
    .quem-somos .quem-somos-info  {
        display: block;
    }
}