.text-border-blue {
  text-shadow:
    2px 0 0 var(--text-blue),
    -2px 0 0 var(--text-blue),
    0 2px 0 var(--text-blue),
    0 -2px 0 var(--text-blue),
    1px 1px var(--text-blue),
    -1px -1px 0 var(--text-blue),
    1px -1px 0 var(--text-blue),
    -1px 1px 0 var(--text-blue);
}

.text-border-white {
  text-shadow:
    2px 0 0 #fff,
    -2px 0 0 #fff,
    0 2px 0 #fff,
    0 -2px 0 #fff,
    1px 1px #fff,
    -1px -1px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff;
}
