.products-slide-menu .products-menu-nav {
  margin-top: -20px;
}

.products-slide-menu ul {
  list-style: none;
  padding: 0 15px;
  font-size: 16px;
}

.products-slide-menu li {
  padding: 3px 0;
}

.products-slide-menu a {
  color: var(--white);
  text-decoration: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  left: 20px;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--white);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: var(--text-blue);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: var(--blue);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  height: 80% !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
  outline: none;
}

.bm-item {
  color: var(--white);
  font-family: 'Bree Serif', serif;
  text-decoration: none;
  padding: 10px;
}

.bm-item.active {
  font-weight: bold;
  text-decoration: underline;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 800px) {
  .bm-burger-button {
    display: none;
  }
}
