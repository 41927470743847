.footer-info {
  background-color: var(--blue);
  color: var(--white);
  font-family: 'Bree Serif', serif;
  font-size: 18px;
}

.footer-info a {
  text-decoration: none;
  color: var(--white);
}

.footer-info-content {
  display: grid;
  grid-template-areas: 'div div';
  grid-template-columns: 75% auto;
  padding: 20px;
}

.footer-info-content .company-name {
  font-size: 24px;
}

.footer-info-content address {
  font-style: normal;
}

.footer-info-content .whatsapp-logo {
  width: 22px;
  vertical-align: middle;
  padding-right: 5px;
  filter: saturate(3%) invert(100%);
}

.footer-info-content .text {
  padding-top: 5px;
}

.footer-rights {
  background-color: var(--dark-blue);
  color: var(--white);
  font-family: 'Bree Serif', serif;
  font-size: 14px;
}

.footer-rights a {
  text-decoration: none;
  color: var(--white);
}

.footer-rights .footer-rights-content {
  display: grid;
  grid-template-areas: 'div div';
  grid-template-columns: 75% auto;
  padding: 10px 20px;
}

@media screen and (max-width: 860px) {
  
  .footer-info-content {
    grid-template-columns: 65% auto;
  }
  
  .footer-rights .footer-rights-content {
    grid-template-columns: 65% auto;
  }
}

@media screen and (max-width: 780px) {
  
  .footer-info-content {
    display: block;
  }
  
  .footer-info-content address {
    margin-top: 20px;
  }
  
  .footer-rights .footer-rights-content {
    display: block;
  }
}
