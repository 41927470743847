/* TOP */
.header {
  font-family: 'Bree Serif', serif;
}
.header .header-top-fixed {
  color: var(--text-blue);
  background-color: var(--white);
  font-size: 20px;
  height: 50px;
  position:fixed;
  z-index: 100;
  border-bottom: solid 3px var(--text-blue);
  margin-top: -60px;
}

.header .header-top-fixed .header-top-fixed-content {
  display: grid;
  grid-template-areas: 'div div';
}

.header .header-top-fixed .header-top-bars {
  background-color: var(--blue);
  height: 50px;
  margin-left: -20px;
}

.header .header-top-fixed .header-top-logo {
  padding-top: 5px;
}

.header .header-top-fixed .header-top-text {
  text-align: right;
  padding-top: 10px;
}

@keyframes slidedown {
  from {
    margin-top: -60px;
  }

  to {
    margin-top: 0px;
  }
}

@keyframes slideup {
  from {
    margin-top: 0px;
  }

  to {
    margin-top: -60px;
  }
}

.header-slidedown {
  animation-duration: .3s;
  animation-name: slidedown;
  animation-fill-mode: forwards;
}

.header-slideup {
  animation-duration: .3s;
  animation-name: slideup;
  animation-fill-mode: forwards;
}

.header .header-top {
  color: var(--white);
  background-color: var(--blue);
  padding: 10px 0 12px;
  font-size: 20px;
  height: 50px;
  position: relative;
  z-index: 20;
}

.header .header-top .header-top-content {
  display: grid;
  grid-template-areas: 'div div';
}

.header .header-top .header-top-content .header-telephones {
  display: block;
  font-size: 16px;
}

.header .header-top .header-top-content .header-telephones span {
  display: block;
}

.header .header-top .header-top-content div:last-child {
  text-align: right;
}

/* DIVISORIA */
.header .header-line {
  height: 3px;
  background-color: var(--dark-blue);
  position: relative;
  z-index: 20;
}

/* LOGO MENU ENDERECO */
.header .header-nav {
  display: grid;
  grid-template-areas: 'img nav address';
  grid-gap: 20px;
  grid-template-columns: 120px auto 240px;
}

.header .header-logo {
  padding: 10px;
}

.header .logo-slogan {
  font-family: 'Bree Serif', serif;
  font-size: 18px;
  color: var(--text-blue);
  padding-top: 30px;
}

.header address {
  font-family: 'Bree Serif', serif;
  font-style: normal;
  font-size: 16px;
  padding-top: 10px;
  color: var(--text-blue);
}

/* MENU */
.header .header-menu {
  font-family: 'Roboto Slab', serif;
  font-size: 22px;
  font-weight: bold;
}

.header .header-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header .header-menu ul li {
  display: inline-table;
  padding: 60px 25px 20px;
}

.header .header-menu ul li.active {
  background-color: var(--dark-blue);
}

.header .header-menu ul li.active a {
  color: var(--white);
}

.header .header-menu ul li a {
  color: var(--text-blue);
  text-decoration: none;
}

@media screen and (max-width: 1130px) {
  
  .header .header-nav {
    grid-template-areas: 'img nav';
    grid-template-columns: 120px auto;
  }
  
  .header address {
    display: none;
  }
}


@media screen and (max-width: 870px) {

  .header .header-menu {
    font-size: 18px;
  }
}

@media screen and (max-width: 950px) {
  .header .header-top .header-top-content {
    display: block;
  }

  .header .header-top .header-top-content div:last-child {
    text-align: left;
  }

  .header .header-top .header-top-content .header-telephones span {
    display: inline-block;
    padding-left: 20px;
  }
}

@media screen and (max-width: 800px) {
  
  .header .header-top-fixed .header-top-fixed-content {
    grid-template-columns: 50px auto;
  }

  .header .header-top-fixed .header-top-logo {
    text-align: center;
  }

  .header .header-top .header-top-content {
    display: block;
    padding-left: 60px;
  }

  .header .header-top .header-top-content .header-telephones {
    grid-template-columns: auto 100px;
    display: grid;
    margin-top: -5px;
  }

  .header .header-top .header-top-content .header-telephones span {
    display: block;
    padding: 0;
  }

  .header .header-nav {
    grid-template-areas: 'img div address';
    grid-template-columns: 120px auto auto;
  }

  .header address {
    display: initial;
  }
}

@media screen and (max-width: 700px) {

  .header .header-nav {
    grid-template-areas: 'img div';
    grid-template-columns: 80px auto;
  }

  .header .header-logo {
    width: 70px;
  }

  .header .logo-slogan {
    font-size: 15px;
    padding-top: 20px;
  }

  .header address {
    display: none;
  }

  .header .header-top .header-top-content .header-telephones .sales {
    font-size: 12px;
  }
}
