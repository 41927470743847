.only-mobile {
  display: none !important;
}

@media screen and (max-width: 800px) {
  .only-desktop {
    display: none !important;
  }

  .only-mobile {
    display: block !important;
  }
}
