.boxes {
  margin-top: 50px;
  display: grid;
  grid-template-areas: 'img img';
  grid-gap: 20px;
}

@media screen and (max-width: 1136px) {
  .boxes img {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {

  .boxes {
    grid-template-areas: 'img';
  }
}
