.quem-somos .quem-somos-products {
    padding-top: 40px;
    padding-bottom: 40px;
}

.quem-somos .quem-somos-products .title {
    color: var(--text-blue);
}

.quem-somos .quem-somos-products-list {
    display: grid;
    grid-template-areas: 'div div div';
    grid-template-columns: 33% 33% 33%;
    padding: 20px;
}

.quem-somos .quem-somos-products-list img {
    padding-top: 30px;
}

@media screen and (max-width: 910px) {
    .quem-somos .quem-somos-products-list {
        grid-template-areas: 'div div';
        grid-template-columns: 50% 50%;
        text-align: center;
    }
}

@media screen and (max-width: 600px) {
    .quem-somos .quem-somos-products-list {
        display: block;
        text-align: center;
    }
}