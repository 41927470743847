.whatsapp-number {
    display: inline;
}

@media screen and (max-width: 520px) {
    .whatsapp-number {
        display: block;
    }

    .whatsapp-number.number2,
    .whatsapp-number.number3 {
        padding-left: 60px;
    }
}