.slider .slider-next img,
.slider .slider-previous img {
  width: 60px;
  color: #ffffff;
  filter: saturate(3%) invert(100%) drop-shadow(0px 2px 0px var(--blue));
}

.slider .slider-next:hover,
.slider .slider-previous:hover {
  filter: drop-shadow(0px 5px 0px var(--blue));
}

.slider .slider-next,
.slider .slider-previous {
  cursor: pointer;
}

.slider .hide {
  display: none;
}

.slider-control-bottomcenter ul {
  top: 35px !important;
  z-index: 100;
}

.slider-control-bottomcenter ul button:focus {
  outline: none;
}

.slider-control-bottomcenter ul .paging-dot {
  width: 10px !important;
  height: 10px !important;
  margin: 0 10px;
}

@media screen and (max-width: 460px) {
  
  .slider-control-bottomcenter {
    display: none !important;
  }
}
