.contato .contato-banner {
    font-family: 'Roboto Slab', serif;
    font-size: 34px;
    background-image: url('/images/blue-banner-bg.jpg');
    background-repeat: no-repeat;
    background-position: top;
    padding: 45px 0;
    text-align: center;
    color: var(--white);
    font-weight: bold;
}

@media screen and (max-width: 800px) {

    .contato .contato-banner {
        font-size: 26px;
    }
}

@media screen and (max-width: 800px) {

    .contato .contato-banner {
        font-size: 26px;
    }
}
