.blue {
  color: var(--blue);
}

.text-blue {
  color: var(--text-blue);
}

.white {
  color: var(--white);
}

.black {
  color: var(--black);
}
