@import url(https://fonts.googleapis.com/css?family=Bree+Serif|Roboto+Slab&display=swap);
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 100%;
}

.content {
  margin: 0 auto;
  max-width: 1100px;
  padding: 0 20px;
}

@media screen and (max-width: 414px) {
  .content {
    padding: 0 15px;
  }
}

:root {
  --blue: #0153fa;
  --dark-blue: #0a48cc;
  --text-blue: #156fc4;
  --white: #ffffff;
  --black: #000000;
  --text-black: #3c3c3c;
}

.text-border-blue {
  text-shadow:
    2px 0 0 var(--text-blue),
    -2px 0 0 var(--text-blue),
    0 2px 0 var(--text-blue),
    0 -2px 0 var(--text-blue),
    1px 1px var(--text-blue),
    -1px -1px 0 var(--text-blue),
    1px -1px 0 var(--text-blue),
    -1px 1px 0 var(--text-blue);
}

.text-border-white {
  text-shadow:
    2px 0 0 #fff,
    -2px 0 0 #fff,
    0 2px 0 #fff,
    0 -2px 0 #fff,
    1px 1px #fff,
    -1px -1px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff;
}

h1.title, h2.title, h3.title, h4.title {
  font-family: 'Roboto Slab', serif;
}

.blue {
  color: var(--blue);
}

.text-blue {
  color: var(--text-blue);
}

.white {
  color: var(--white);
}

.black {
  color: var(--black);
}

.only-mobile {
  display: none !important;
}

@media screen and (max-width: 800px) {
  .only-desktop {
    display: none !important;
  }

  .only-mobile {
    display: block !important;
  }
}

/* TOP */
.header {
  font-family: 'Bree Serif', serif;
}
.header .header-top-fixed {
  color: var(--text-blue);
  background-color: var(--white);
  font-size: 20px;
  height: 50px;
  position:fixed;
  z-index: 100;
  border-bottom: solid 3px var(--text-blue);
  margin-top: -60px;
}

.header .header-top-fixed .header-top-fixed-content {
  display: grid;
  grid-template-areas: 'div div';
}

.header .header-top-fixed .header-top-bars {
  background-color: var(--blue);
  height: 50px;
  margin-left: -20px;
}

.header .header-top-fixed .header-top-logo {
  padding-top: 5px;
}

.header .header-top-fixed .header-top-text {
  text-align: right;
  padding-top: 10px;
}

@-webkit-keyframes slidedown {
  from {
    margin-top: -60px;
  }

  to {
    margin-top: 0px;
  }
}

@keyframes slidedown {
  from {
    margin-top: -60px;
  }

  to {
    margin-top: 0px;
  }
}

@-webkit-keyframes slideup {
  from {
    margin-top: 0px;
  }

  to {
    margin-top: -60px;
  }
}

@keyframes slideup {
  from {
    margin-top: 0px;
  }

  to {
    margin-top: -60px;
  }
}

.header-slidedown {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-name: slidedown;
          animation-name: slidedown;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.header-slideup {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-name: slideup;
          animation-name: slideup;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.header .header-top {
  color: var(--white);
  background-color: var(--blue);
  padding: 10px 0 12px;
  font-size: 20px;
  height: 50px;
  position: relative;
  z-index: 20;
}

.header .header-top .header-top-content {
  display: grid;
  grid-template-areas: 'div div';
}

.header .header-top .header-top-content .header-telephones {
  display: block;
  font-size: 16px;
}

.header .header-top .header-top-content .header-telephones span {
  display: block;
}

.header .header-top .header-top-content div:last-child {
  text-align: right;
}

/* DIVISORIA */
.header .header-line {
  height: 3px;
  background-color: var(--dark-blue);
  position: relative;
  z-index: 20;
}

/* LOGO MENU ENDERECO */
.header .header-nav {
  display: grid;
  grid-template-areas: 'img nav address';
  grid-gap: 20px;
  grid-template-columns: 120px auto 240px;
}

.header .header-logo {
  padding: 10px;
}

.header .logo-slogan {
  font-family: 'Bree Serif', serif;
  font-size: 18px;
  color: var(--text-blue);
  padding-top: 30px;
}

.header address {
  font-family: 'Bree Serif', serif;
  font-style: normal;
  font-size: 16px;
  padding-top: 10px;
  color: var(--text-blue);
}

/* MENU */
.header .header-menu {
  font-family: 'Roboto Slab', serif;
  font-size: 22px;
  font-weight: bold;
}

.header .header-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header .header-menu ul li {
  display: inline-table;
  padding: 60px 25px 20px;
}

.header .header-menu ul li.active {
  background-color: var(--dark-blue);
}

.header .header-menu ul li.active a {
  color: var(--white);
}

.header .header-menu ul li a {
  color: var(--text-blue);
  text-decoration: none;
}

@media screen and (max-width: 1130px) {
  
  .header .header-nav {
    grid-template-areas: 'img nav';
    grid-template-columns: 120px auto;
  }
  
  .header address {
    display: none;
  }
}


@media screen and (max-width: 870px) {

  .header .header-menu {
    font-size: 18px;
  }
}

@media screen and (max-width: 950px) {
  .header .header-top .header-top-content {
    display: block;
  }

  .header .header-top .header-top-content div:last-child {
    text-align: left;
  }

  .header .header-top .header-top-content .header-telephones span {
    display: inline-block;
    padding-left: 20px;
  }
}

@media screen and (max-width: 800px) {
  
  .header .header-top-fixed .header-top-fixed-content {
    grid-template-columns: 50px auto;
  }

  .header .header-top-fixed .header-top-logo {
    text-align: center;
  }

  .header .header-top .header-top-content {
    display: block;
    padding-left: 60px;
  }

  .header .header-top .header-top-content .header-telephones {
    grid-template-columns: auto 100px;
    display: grid;
    margin-top: -5px;
  }

  .header .header-top .header-top-content .header-telephones span {
    display: block;
    padding: 0;
  }

  .header .header-nav {
    grid-template-areas: 'img div address';
    grid-template-columns: 120px auto auto;
  }

  .header address {
    display: inline;
    display: initial;
  }
}

@media screen and (max-width: 700px) {

  .header .header-nav {
    grid-template-areas: 'img div';
    grid-template-columns: 80px auto;
  }

  .header .header-logo {
    width: 70px;
  }

  .header .logo-slogan {
    font-size: 15px;
    padding-top: 20px;
  }

  .header address {
    display: none;
  }

  .header .header-top .header-top-content .header-telephones .sales {
    font-size: 12px;
  }
}

.footer-info {
  background-color: var(--blue);
  color: var(--white);
  font-family: 'Bree Serif', serif;
  font-size: 18px;
}

.footer-info a {
  text-decoration: none;
  color: var(--white);
}

.footer-info-content {
  display: grid;
  grid-template-areas: 'div div';
  grid-template-columns: 75% auto;
  padding: 20px;
}

.footer-info-content .company-name {
  font-size: 24px;
}

.footer-info-content address {
  font-style: normal;
}

.footer-info-content .whatsapp-logo {
  width: 22px;
  vertical-align: middle;
  padding-right: 5px;
  -webkit-filter: saturate(3%) invert(100%);
          filter: saturate(3%) invert(100%);
}

.footer-info-content .text {
  padding-top: 5px;
}

.footer-rights {
  background-color: var(--dark-blue);
  color: var(--white);
  font-family: 'Bree Serif', serif;
  font-size: 14px;
}

.footer-rights a {
  text-decoration: none;
  color: var(--white);
}

.footer-rights .footer-rights-content {
  display: grid;
  grid-template-areas: 'div div';
  grid-template-columns: 75% auto;
  padding: 10px 20px;
}

@media screen and (max-width: 860px) {
  
  .footer-info-content {
    grid-template-columns: 65% auto;
  }
  
  .footer-rights .footer-rights-content {
    grid-template-columns: 65% auto;
  }
}

@media screen and (max-width: 780px) {
  
  .footer-info-content {
    display: block;
  }
  
  .footer-info-content address {
    margin-top: 20px;
  }
  
  .footer-rights .footer-rights-content {
    display: block;
  }
}

.products-slide-menu .products-menu-nav {
  margin-top: -20px;
}

.products-slide-menu ul {
  list-style: none;
  padding: 0 15px;
  font-size: 16px;
}

.products-slide-menu li {
  padding: 3px 0;
}

.products-slide-menu a {
  color: var(--white);
  text-decoration: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  left: 20px;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--white);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: var(--text-blue);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: var(--blue);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  height: 80% !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
  outline: none;
}

.bm-item {
  color: var(--white);
  font-family: 'Bree Serif', serif;
  text-decoration: none;
  padding: 10px;
}

.bm-item.active {
  font-weight: bold;
  text-decoration: underline;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 800px) {
  .bm-burger-button {
    display: none;
  }
}

.slider .slider-next img,
.slider .slider-previous img {
  width: 60px;
  color: #ffffff;
  -webkit-filter: saturate(3%) invert(100%) drop-shadow(0px 2px 0px var(--blue));
          filter: saturate(3%) invert(100%) drop-shadow(0px 2px 0px var(--blue));
}

.slider .slider-next:hover,
.slider .slider-previous:hover {
  -webkit-filter: drop-shadow(0px 5px 0px var(--blue));
          filter: drop-shadow(0px 5px 0px var(--blue));
}

.slider .slider-next,
.slider .slider-previous {
  cursor: pointer;
}

.slider .hide {
  display: none;
}

.slider-control-bottomcenter ul {
  top: 35px !important;
  z-index: 100;
}

.slider-control-bottomcenter ul button:focus {
  outline: none;
}

.slider-control-bottomcenter ul .paging-dot {
  width: 10px !important;
  height: 10px !important;
  margin: 0 10px;
}

@media screen and (max-width: 460px) {
  
  .slider-control-bottomcenter {
    display: none !important;
  }
}

.whatsapp-number {
    display: inline;
}

@media screen and (max-width: 520px) {
    .whatsapp-number {
        display: block;
    }

    .whatsapp-number.number2,
    .whatsapp-number.number3 {
        padding-left: 60px;
    }
}
.banner-container {
  width: 100%;
}

.banner-container .banner {
  height: 780px;
  text-align: center;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.banner-container .banner-mask {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.15;
}

.banner-container .banner-title {
  font-family: 'Roboto Slab', serif;
  font-size: 64px;
  font-weight: bold;
  color: var(--dark-blue);
  padding-top: 100px;
  margin: 0;
  position: relative;
  z-index: 10;
}

.banner-container .banner-description {
  font-family: 'Bree Serif', serif;
  font-size: 55px;
  color: var(--white);
  padding-top: 60px;
  position: relative;
  z-index: 10;
  max-width: 1000px;
  margin: 0 auto;
}

.banner-container .banner1 {
  background-image: url('/images/home/cozinha-montagem-moveis.jpg');
}

.banner-container .banner2 {
  background-image: url('/images/home/porto-containers.jpg');
}

.banner-container .banner3 {
  background-image: url('/images/home/blue-splash.jpg');
}

.banner-container .banner3 .corredicas {
  position: absolute;
  margin-top: -80px;
  left: calc(50% - 600px);
}

.banner-container .banner3 .dobradicas {
  position: absolute;
  margin-top: -20px;
  left: calc(50% + 100px);
}

.banner-container .banner4 {
  background-image: url('/images/home/blue-splash-inverted.jpg');
}

.banner-container .banner4 .chipboard {
  position: absolute;
  margin-top: -20px;
  width: 390px;
  left: calc(50% - 500px);
}

.banner-container .banner4 .trombeta {
  position: absolute;
  margin-top: 150px;
  width: 400px;
  left: calc(50% + 100px);
}

@media screen and (max-width: 800px) {

  .banner-container .banner {
    height: 700px;
    padding: 0 10px;
  }

  .banner-container .banner-mask {
    margin-left: -10px;
  }
  
  .banner-container .banner-title {
    font-size: 45px;
  }
  
  .banner-container .banner-description {
    font-size: 40px;
  }

  .banner-container .banner1 {
    background-position: right;
  }

  .banner-container .banner3 .corredicas {
    margin-top: -80px;
    left: calc(4%);
    width: 700px;
  }

  .banner-container .banner3 .dobradicas {
    margin-top: 0px;
    left: calc(45%);
    width: 400px;
  }

  .banner-container .banner4 .chipboard {
    margin-top: -20px;
    width: 320px;
    left: calc(50% - 320px);
  }

  .banner-container .banner4 .trombeta {
    margin-top: 150px;
    width: 330px;
    left: calc(50% + 20px);
  }
}

@media screen and (max-width: 600px) {
  
  .banner-container .banner-title {
    padding-top: 50px;
  }

  .banner-container .banner1 {
    background-position: -400px;
  }

  
  .banner-container .banner3 .corredicas {
    margin-top: -80px;
    left: calc(4%);
    width: 90%;
  }

  .banner-container .banner3 .dobradicas {
    margin-top: 0px;
    left: calc(35%);
    width: 60%;
  }

  .banner-container .banner4 .chipboard {
    margin-top: -15px;
    width: 50%;
    left: calc(50% - 150px);
  }

  .banner-container .banner4 .trombeta {
    margin-top: 170px;
    width: 50%;
    left: calc(45%);
  }
}

@media screen and (max-width: 330px) {
  
  .banner-container .banner-title {
    font-size: 40px;
  }
  
  .banner-container .banner-description {
    font-size: 35px;
  }
}

.boxes {
  margin-top: 50px;
  display: grid;
  grid-template-areas: 'img img';
  grid-gap: 20px;
}

@media screen and (max-width: 1136px) {
  .boxes img {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {

  .boxes {
    grid-template-areas: 'img';
  }
}

.strip-products {
  background-image: url('/images/blue-banner-bg.jpg');
  background-position: top;
  margin-top: 20px;
  height: 370px;
}

.strip-products .strip-products-content {
  display: grid;
  grid-template-areas: 'img div';
  grid-template-columns: 480px auto;
}

.strip-products .strip-products-content img {
  padding-left: 30px;
}

.strip-products .strip-products-content .strip-title {
  font-family: 'Roboto Slab', serif;
  font-size: 36px;
  font-weight: bold;
  color: var(--white);
  margin: 40px 0;
}
  
.strip-products .strip-description {
  font-family: 'Bree Serif', serif;
  font-size: 36px;
  color: var(--white);
}

@media screen and (max-width: 1100px) {

  .strip-products {
    height: 300px;
  }

  .strip-products .strip-products-content {
    grid-template-columns: 360px auto;
  }
  
  .strip-products .strip-products-content img {
    width: 100%;
    padding-left: 15px;
  }
  
  .strip-products .strip-products-content .strip-title {
    font-size: 30px;
  }
  
  .strip-products .strip-products-content .strip-description {
    font-size: 30px;
  }
}

@media screen and (max-width: 800px) {
  
  .strip-products {
    height: 280px;
  }

  .strip-products .strip-products-content {
    grid-template-columns: 280px auto;
  }

  .strip-products .strip-products-content img {
    margin-top: 20px;
  }

  .strip-products .strip-products-content .strip-title {
    margin: 20px 0;
  }

  .strip-products .strip-products-content .strip-description {
    font-size: 25px;
  }
}

@media screen and (max-width: 670px) {
  
  .strip-products {
    height: 220px;
  }

  .strip-products .strip-products-content .strip-description {
    display: none;
  }

  .strip-products .strip-products-content img {
    margin-top: 0;
  }
}

@media screen and (max-width: 540px) {
  
  .strip-products {
    height: 400px;
  }

  .strip-products .strip-products-content {
    text-align: center;
  }

  .strip-products .strip-products-content img {
    margin-top: -20px;
    width: 100%;
    max-width: 400px;
  }

  .strip-products .strip-products-content {
    display: block;
  }

  .strip-products .strip-products-content .strip-title {
    margin-top: -10px;
  }
}

.home-products-section {
  margin-top: 30px;
}

.home-products-section .title {
}

.home-products-list {
  display: grid;
  grid-template-areas: 'div div div';
  margin-top: 30px;
  margin-bottom: 60px;
}

.home-products-list .product-item {
  text-align: center;
}

.home-products-list .product-item .product-name {
  font-family: 'Bree Serif', serif;
  display: block;
  font-size: 30px;
  padding: 20px 10px;
}

@media screen and (max-width: 850px) {
  
  .home-products-list {
    grid-template-areas: 'div div';
  }
  
  .home-products-list .product-item {
    margin-top: 30px;
  }
}

@media screen and (max-width: 570px) {
  
  .home-products-list {
    display: block;
  }
}

.products .products-banner {
  font-family: 'Roboto Slab', serif;
  font-size: 34px;
  background-image: url('/images/blue-banner-bg.jpg');
  background-repeat: no-repeat;
  background-position: top;
  padding: 45px 0;
  text-align: center;
  color: var(--white);
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  
  .products .products-banner {
    font-size: 26px;
  }
}

@media screen and (max-width: 800px) {
  
  .products .products-banner {
    font-size: 26px;
  }
}

.products-content {
  margin-bottom: 40px;
  max-width: 1366px;
  margin: 20px auto;
  padding: 0 20px;
}

.products-content .product-info {
  position: relative;
  width: calc(100% - 320px);
  padding-left: 320px;
  min-height: 1000px;
  padding-bottom: 30px;
}

.products-content .product-info .product-slide .product-info-image {
  object-fit: cover;
  height: 450px;
}

.products-content .product-info .product-slide {
}

.products-content .product-info .product-text {
  font-size: 18px;
  font-family: 'Roboto Slab', serif;
}

.products-content .product-info .product-text .table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  text-align: center;
}
.products-content .product-info .product-text .table tr:hover th,
.products-content .product-info .product-text .table tr:hover td {
  background-color: rgb(230, 230, 230);
}

.products-content .product-info .product-text .table th {
  background-color: rgb(240, 240, 240);
}

.products-content .product-info .product-text .table th,
.products-content .product-info .product-text .table td {
  border: solid 1px rgb(200, 200, 200);
  padding: 3px;
}

.products-content .product-info .product-text .table td {
  color: var(--text-blue);
  font-size: 22px;
}

.products-content .product-info .product-text h2 {
  font-family: 'Roboto Slab', serif;
  font-size: 28px;
  color: var(--text-blue);
  margin-top: 60px;
  line-height: 30px;
}

.products-content .product-info .product-text h3 {
  margin-top: 40px;
  margin-bottom: 10px;
  color: var(--text-blue);
}

@media screen and (max-width: 1200px) {
  .products-content .product-info .product-slide .product-info-image {
    height: 350px;
  }
}

@media screen and (max-width: 900px) {
  .products-content .product-info .product-slide .product-info-image {
    height: 300px;
  }
}

@media screen and (max-width: 800px) {
  
  .products-content .product-info {
    position: relative;
    width: 100%;
    padding-left: 0px;
  }
}

@media screen and (max-width: 500px) {
  .products-content .product-info .product-slide .product-info-image {
    height: 250px;
  }
}

@media screen and (max-width: 414px) {
  .products-content .product-info .product-slide .product-info-image {
    height: 200px;
  }
}

.products .products-menu {
  width: 300px;
  height: 100%;
  position: absolute;
  z-index: 10;
}

.products .products-menu .products-menu-header {
  font-family: 'Roboto Slab', serif;
  font-size: 24px;
  text-align: center;
  background-color: var(--blue);
  color: var(--white);
  padding: 20px 0;
}

.products .products-menu .products-menu-nav {
  background-color: var(--dark-blue);
  font-family: 'Bree Serif', serif;
  padding-top: 20px;
  padding-bottom: 40px;
}

.products .products-menu .products-menu-nav ul {
  margin: 0;
  padding: 0 30px;
  list-style: none;
}

.products .products-menu .products-menu-nav ul a {
  color: var(--white);
  text-decoration: none;
}

.products .products-menu .products-menu-nav .product-menu-item {
  font-weight: bold;
  font-size: 20px;
  padding: 10px 0;
}

.products .products-menu .products-menu-nav .product-submenu .product-menu-item {
  font-weight: normal;
  font-size: 18px;
  padding: 5px 0;
}

.product-menu-item.active a {
  font-weight: bold;
  text-decoration: underline !important;
}

.quem-somos .quem-somos-banner {
    background-image: url('/images/blue-banner-bg.jpg');
    height: 678px;
    width: 100%;
    text-align: center;
    background-position: center;
}

.quem-somos .quem-somos-banner .banner-logo {
    padding-top: 30px;
}

.quem-somos .quem-somos-banner .text-banner {
    text-align: center;
    font-family: 'Roboto Slab', serif;
    font-size: 50px;
    font-weight: bold;
    color: var(--dark-blue);
    max-width: 1200px;
    margin: 0 auto;
}

@media screen and (max-width: 930px) {
    .quem-somos .quem-somos-banner .text-banner {
        font-size: 40px;
    }
}

@media screen and (max-width: 500px) {
    .quem-somos .quem-somos-banner .banner-logo {
        width: 300px;
    }

    .quem-somos .quem-somos-banner .text-banner {
        font-size: 35px;
    }
}
.quem-somos {
    padding-bottom: 40px;
}

.quem-somos .quem-somos-text {
    font-family: 'Roboto Slab', serif;
    font-size: 22px;
    color: var(--text-black);
}

.quem-somos .quem-somos-text .title,
.quem-somos .quem-somos-info .title {
    color: var(--text-blue);
}
.quem-somos .quem-somos-info {
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
    color: var(--text-black);
    display: grid;
    grid-template-areas: 'div div';
    grid-template-columns: 50% 50%;
    padding: 20px;
}

.quem-somos .quem-somos-info address {
    font-style: normal;
}

@media screen and (max-width: 920px) {
    .quem-somos .quem-somos-info  {
        display: block;
    }
}
.quem-somos .quem-somos-products {
    padding-top: 40px;
    padding-bottom: 40px;
}

.quem-somos .quem-somos-products .title {
    color: var(--text-blue);
}

.quem-somos .quem-somos-products-list {
    display: grid;
    grid-template-areas: 'div div div';
    grid-template-columns: 33% 33% 33%;
    padding: 20px;
}

.quem-somos .quem-somos-products-list img {
    padding-top: 30px;
}

@media screen and (max-width: 910px) {
    .quem-somos .quem-somos-products-list {
        grid-template-areas: 'div div';
        grid-template-columns: 50% 50%;
        text-align: center;
    }
}

@media screen and (max-width: 600px) {
    .quem-somos .quem-somos-products-list {
        display: block;
        text-align: center;
    }
}
.contato .contato-banner {
    font-family: 'Roboto Slab', serif;
    font-size: 34px;
    background-image: url('/images/blue-banner-bg.jpg');
    background-repeat: no-repeat;
    background-position: top;
    padding: 45px 0;
    text-align: center;
    color: var(--white);
    font-weight: bold;
}

@media screen and (max-width: 800px) {

    .contato .contato-banner {
        font-size: 26px;
    }
}

@media screen and (max-width: 800px) {

    .contato .contato-banner {
        font-size: 26px;
    }
}

.contato {
    font-family: 'Roboto Slab', serif;
    font-size: 22px;
    color: var(--text-black);
    padding-bottom: 40px;
}

.contato a {
    text-decoration: none;
    color: var(--text-black);
}

.contato a:hover {
    color: var(--text-blue);
}

.contato .title {
    color: var(--text-blue);
    margin-top: 40px;
    margin-bottom: 10px;
}

.contato .contato-text {
    font-size: 28px;
}

.contato .contato-options  .whatsapp-logo {
    width: 30px;
    vertical-align: middle;
    padding-right: 10px;
}

.contato .contato-maps {
    margin-top: 20px;
}

.contato .contato-info {
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
    color: var(--text-black);
    display: grid;
    grid-template-areas: 'div div';
    grid-template-columns: 50% 50%;
    padding: 20px;
}

.contato .contato-info address {
    font-style: normal;
}

@media screen and (max-width: 870px) {
    .contato .contato-info {
        display: block;
    }
}


