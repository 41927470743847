.strip-products {
  background-image: url('/images/blue-banner-bg.jpg');
  background-position: top;
  margin-top: 20px;
  height: 370px;
}

.strip-products .strip-products-content {
  display: grid;
  grid-template-areas: 'img div';
  grid-template-columns: 480px auto;
}

.strip-products .strip-products-content img {
  padding-left: 30px;
}

.strip-products .strip-products-content .strip-title {
  font-family: 'Roboto Slab', serif;
  font-size: 36px;
  font-weight: bold;
  color: var(--white);
  margin: 40px 0;
}
  
.strip-products .strip-description {
  font-family: 'Bree Serif', serif;
  font-size: 36px;
  color: var(--white);
}

@media screen and (max-width: 1100px) {

  .strip-products {
    height: 300px;
  }

  .strip-products .strip-products-content {
    grid-template-columns: 360px auto;
  }
  
  .strip-products .strip-products-content img {
    width: 100%;
    padding-left: 15px;
  }
  
  .strip-products .strip-products-content .strip-title {
    font-size: 30px;
  }
  
  .strip-products .strip-products-content .strip-description {
    font-size: 30px;
  }
}

@media screen and (max-width: 800px) {
  
  .strip-products {
    height: 280px;
  }

  .strip-products .strip-products-content {
    grid-template-columns: 280px auto;
  }

  .strip-products .strip-products-content img {
    margin-top: 20px;
  }

  .strip-products .strip-products-content .strip-title {
    margin: 20px 0;
  }

  .strip-products .strip-products-content .strip-description {
    font-size: 25px;
  }
}

@media screen and (max-width: 670px) {
  
  .strip-products {
    height: 220px;
  }

  .strip-products .strip-products-content .strip-description {
    display: none;
  }

  .strip-products .strip-products-content img {
    margin-top: 0;
  }
}

@media screen and (max-width: 540px) {
  
  .strip-products {
    height: 400px;
  }

  .strip-products .strip-products-content {
    text-align: center;
  }

  .strip-products .strip-products-content img {
    margin-top: -20px;
    width: 100%;
    max-width: 400px;
  }

  .strip-products .strip-products-content {
    display: block;
  }

  .strip-products .strip-products-content .strip-title {
    margin-top: -10px;
  }
}
